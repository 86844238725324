import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { SalesContractIList, SalesContractIById,SalesContractIAdd, SalesContractIEdit, SalesContractIDel} from "@/api/Sales/SalesContractApi";
//显示列表查询
export const SalesContractIList = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSOContracts",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const SalesContractIById = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSalesContract",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const SalesContractIAdd = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PostSalesContract",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const SalesContractIEdit = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PutSalesContract",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const SalesContractIDel = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/DeleteSalesContract",
        method: "delete",
        params: {
            id
        }
    })
}


//获取列表
export const SOContractsList = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSOContractsList",
        method: "get",
    })
}
