import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { TextileSalesOrdeItemIList, TextileSalesOrdeItemIById,TextileSalesOrdeItemIAdd, TextileSalesOrdeItemIEdit, TextileSalesOrdeItemIDel} from "@/api/Sales/TextileSalesOrdeItemApi";

//显示销售订单及明细列表查询
export const SoAndItems = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSoAndItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//显示列表查询
export const TextileSalesOrdeItemIList = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetTextileSalesOrdeItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const TextileSalesOrdeItemIById = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetTextileSalesOrdeItem",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const TextileSalesOrdeItemIAdd = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PostTextileSalesOrdeItem",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const TextileSalesOrdeItemIEdit = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PutTextileSalesOrdeItem",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const TextileSalesOrdeItemIDel = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/DeleteTextileSalesOrdeItem",
        method: "delete",
        params: {
            id
        }
    })
}



