import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { TextileSalesOrderIList, TextileSalesOrderIById,TextileSalesOrderIAdd, TextileSalesOrderIEdit, TextileSalesOrderIDel} from "@/api/Sales/TextileSalesOrderApi";
//获取物料平均单价
export const SoAvgMaterialPrices = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSoAvgMaterialPrices",
        method: "get",
        params: {
           ...row
        }
    })
}

//显示列表查询
export const TextileSalesOrderIList = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSOs",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const TextileSalesOrderIById = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetTextileSalesOrder",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const TextileSalesOrderIAdd = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PostTextileSalesOrder",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const TextileSalesOrderIEdit = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PutTextileSalesOrder",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const TextileSalesOrderIDel = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/DeleteTextileSalesOrder",
        method: "delete",
        params: {
            id
        }
    })
}



